'use strict';

angular.module('cpformplastApp.orders')
    .controller('OrderManagementController', function($scope, DataManager, $state, Notification) {

  $scope.orders = [];

  $scope.orderTableButtons = [{
    'icon':'plus',
    'title':'Ajouter',
    'function': () => {
      $state.go('orders/order-management/order-creation',{'orderId' : ''});
    }
  }];

  $scope.orderTableStructure = [{
    'title':'Po',
    'value':'po',
    'filter':'alpha'
  },{
    'title':'Fournisseur',
    'value':'supplier',
    'filter':'alpha'
  },{
    'title':'Client',
    'value':'client',
    'filter':'alpha'
  },{
    'title':'Dimensions x Catégorie',
    'value':'dimcat',
    'filter':'alpha'
  },{
    'title':'Date Livraison',
    'value':'deliveryDate',
    'filter':'date'
  },{
    'title':'Job',
    'value':'job',
    'filter':'alpha'
  },{
    'title':'ratio',
    'value':'ratio',
    'filter':'alpha'
  }];

  $scope.init = function() {
    DataManager.fetchActualSuborders().then((data) => {
      var suborders = data;
      var orders = [];

      for (var i=0 ; i<suborders.length ; i++) {
        var alreadyIn = false;

        for (var j=0 ; j<orders.length ; j++) {
          if (orders[j].job === suborders[i].job.number && orders[j]._id === suborders[i].orderId) {
            orders[j].total += suborders[i].quantity;
            orders[j].qte += suborders[i].placed ? suborders[i].quantity : 0;
            alreadyIn = true;
          }
        }

        if (!alreadyIn) {
          orders.push({
            '_id':suborders[i].orderId,
            'po':suborders[i].order.po,
            'supplier':suborders[i].order.supplier,
            'deliveryDate': new Date(suborders[i].deliveryDate),
            'client':suborders[i].job.item.client.name,
            'dimcat':suborders[i].job.item.category.dimension + ' x ' + suborders[i].job.item.category.name,
            'job':suborders[i].job.number,
            'qte':suborders[i].placed ? suborders[i].quantity : 0,
            'total':suborders[i].quantity
          });
        }
      }

      for (var i=0 ; i<orders.length ; i++) {
        orders[i].ratio = Number(orders[i].qte).toLocaleString() + '/' + Number(orders[i].total).toLocaleString() + ' lbs';
      }

      $scope.orders = orders;
    })
    .catch(err => {
      console.log(err);
      Notification.error('Un problème est survenu lors du chargement des commandes');
    });
  };

  $scope.selectOrder = function(order) {
    $state.go('orders/order-management/order-creation', {'orderId': order._id});
  };

  $scope.init();

});
